import { createGtm } from '@gtm-support/vue-gtm';

export const gtm = createGtm({
    id: 'GTM-PBHFT4N',
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: true,
    debug: import.meta.env.NODE_ENV !== 'production',
});
